<template>
  <v-app id="inspire">
    
    <MainMenu :showMenuComplete="showMenuComplete"/>
    <TopBar :showMenuComplete="showMenuComplete"/>

    <v-content>
      <v-container class="" :id="'cnt-'+$router.currentRoute.name" fluid>

        <router-view/>

      </v-container>
    </v-content>

    <Snackbar></Snackbar>
    <DialogMailDev></DialogMailDev>
    
    <Footer v-if="showFooter"/>

  </v-app>
</template>

<script>

import MainMenu from '@/components/main-layout/MainMenu.vue'
import TopBar from '@/components/main-layout/TopBar.vue'
import Footer from '@/components/main-layout/Footer.vue'
import Snackbar from '@/components/main-layout/Snackbar.vue'
import DialogMailDev from '@/components/admin/DialogMailDev.vue'

import router from "@/router/router"


import '@/assets/css/font.css';
import '@/assets/css/app.css';

export default {
  name: 'App',
  
  components: {
    MainMenu, TopBar, Footer, Snackbar, DialogMailDev
  },

  mounted: async function(){
    this.$store.dispatch('app/initLang')
    this.$store.dispatch('app/fetchEntities', { entityType: "instance", query: { name: "main" } })

    this.$store.dispatch('basket/initReproConfig')

    let vueLang = localStorage.getItem("VueAppLanguage")
    if(vueLang == null) vueLang = 'fr' 
    localStorage.setItem("VueAppLanguage", vueLang)
    
    this.lastLang = vueLang ? vueLang : this.currentLangInRoute()
    setTimeout(()=>{
      console.log("*******", this.lastLang)
      console.log("*******fullpath", router.currentRoute.fullPath)
      if(router.currentRoute.fullPath == "/")
        router.push('/'+this.$i18n.locale)
    }, 3000)
    
  },

  data: () => ({
    showFooter: true,
    showMenuComplete: true, 
    lastLang: ''
  }),

  methods: {
    currentLangInRoute : function(){
      //let l = router.currentRoute.path.substr(1,2)
      return router.currentRoute.path.substr(1,2)
    }
  },

  computed: {
  },

  watch: {
    '$route.path': { immediate: true, 
        async handler () {
            console.log("router.currentRoute.fullPath", router.currentRoute.name, router.currentRoute.name == "home") 
            this.showFooter = router.currentRoute.fullPath.indexOf('/admin') == -1 && router.currentRoute.fullPath.indexOf('/commande') == -1
            this.showMenuComplete = router.currentRoute.fullPath.indexOf('/admin') == -1 

            console.log("////////////////////2", this.lastLang, this.currentLangInRoute(), this.$i18n.locale, localStorage.getItem("VueAppLanguage"))
            if(this.lastLang != '' && this.lastLang != this.currentLangInRoute()){
              //this.lastLang = this.currentLangInRoute()
              //router.go()
            }
        }
    },
  }, 

};
</script>
