
import axios from "axios"
import router from "../../router/router"

//const config = require('../../config/' + process.env.NODE_ENV)

const state = () => ({
  user: {
    id: null,
    name: null,
    isAdmin: null,
    access_token: null
  },

  login: {
    success: false,
    error: null,
    loading: false,
  },

  register: {
    success: false,
    error: null,
    loading: false,
  },

  isLogin: false,
})

// getters
const getters = {
  isAdmin: (state) => { return state.user != null && state.user.isAdmin == true },
}

// actions
const actions = {
  async checkAuth({ commit, state }) {
    try {
      if (state.isLogin) { return }

      const res = await axios.get("/auth/whoami")
      if (res.data.error) {
        console.log("Cannot check login state. Err:", res.data.msg)
        console.log("ROUTE", router.currentRoute.meta)
        commit('logout')
        return
      }
      commit('login', res.data)
    } catch (err) {
      commit('logout');
      console.log("Error checking login state. Err:", err)
      //router.push('/')
    } finally {
      //console.log("checkAuth finally")
    }
  },
    
  async login({ commit }, credentials) {
    try {
      commit('fetchLoginLoading', true)
      const { data } = await axios.post('/auth/login', {
        email: credentials.email,
        password: credentials.password
      });

      if (data.error === true) {
        commit("fetchLoginError", data.errorMsg)
        return
      }
      await commit('login', data);

      if(data.user.isAdmin) 
        router.push('/admin')
      else
        router.push('/profile')        
    } 
    catch (err) {
      console.log("User cannot be authenticated. Error:", err)
      commit("fetchLoginError", {
        name: 'NetworkError',
        message: 'Cannot log to server',
        i18nKey: 'instances.unreachableNode'
      })
      commit('logout');
    } finally {
      commit('fetchLoginLoading', false);
    }
  },

  async register({ commit }, credentials) {
    try {
      commit('fetchRegisterLoading', true);
      const { data } = await axios.post('/auth/register', {
        email: credentials.email,
        password: credentials.password
      });

      if (data.error === true) {
        commit("fetchRegisterError", data.msg)
        return
      }
      commit("fetchRegisterError", null)
      commit('fetchRegisterSuccess')
    } catch (err) {
      console.log('ERROR: User cannot be registered. Error:', err)
    } finally {
      commit('fetchRegisterLoading', false)
    }
  },
  
  async logout({ commit }) {
    try {
      commit('logout')
      router.replace('/');
    } catch (err) {
      console.error('CANNOT LOGOUT O-O')
    }
  },
  
}


// mutations
const mutations = {
  login(state, context) {
    state.user = context.user
    //state.user.access_token = context.token
    state.isLogin = true

    if(context.token != null)
      localStorage.setItem("access_token", context.token)

    console.log("state.user", state.user)
  },
  logout(state, data) {
    localStorage.removeItem("access_token")

    state.user = null
    state.isLogin = false

    if(data && data.loginErrorMsg != null)
      state.loginErrorMsg = data.loginErrorMsg
  },


  fetchRegisterLoading(state, isLoading) {
    state.register.loading = isLoading;
  },
  fetchRegisterError(state, err) {
    state.register.error = err;
    state.register.success = false;
  },
  fetchRegisterSuccess(state) {
    state.register.error = null;
    state.register.success = true;
  },
  fetchLoginLoading(state, isLoading) {
    state.login.loading = isLoading;
  },
  fetchLoginError(state, err) {
    state.login.error = err;
    state.login.success = false;
    state.isLogin = false;
  },
  fetchLoginSuccess(state) {
    state.login.error = null;
    state.login.success = true;
    state.isLogin = true;
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}