import Vue from 'vue'
import VueRouter from 'vue-lang-router'
//import store from '../store'

import NProgress from 'nprogress'
import store from "../store"

import translations from '../lang/translations'
import localizedURLs from '../lang/localized-urls'

NProgress.configure({ showSpinner: false });

Vue.use(VueRouter, {
  defaultLanguage: 'fr',
  translations,
  localizedURLs,
});

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/home.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/register.vue')
  },
  {
    path: '/conditions',
    name: 'cgv',
    component: () => import('../views/cgv.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/contact.vue')
  },
  {
    path: '/gallery',
    name: 'gallery',
    component: () => import('../views/gallery.vue')
  },
  {
    path: '/shop',
    name: 'shop',
    component: () => import('../views/shop.vue')
  },
  {
    path: '/commande',
    name: 'commande',
    component: () => import('../views/commande.vue')
  },
  {
    path: '/extras',
    name: 'extras',
    component: () => import('../views/extras.vue')
  },
  {
    path: '/ateliers',
    name: 'ateliers',
    component: () => import('../views/extras.vue')
  },
  {
    path: '/panier/:repro?/:support?/:size?',
    name: 'panier',
    component: () => import('../views/basket/basket.vue'),
  },
  {
    path: '/product/:productId',
    name: 'product',
    component: () => import('../views/product.vue'),
  },


  /* PAYMENT RESERVATION */
  {
    path: '/payment/reservation/all/:resaId',
    name: 'profile',
    component: () => import('../views/payment/reservation/all.vue')
  },
  {
    path: '/payment/reservation/additional/:resaId',
    name: 'profile',
    component: () => import('../views/payment/reservation/additional.vue')
  },
  {
    path: '/payment/reservation/thanks/:resaId',
    name: 'profile',
    component: () => import('../views/payment/reservation/thanks.vue')
  },
  {
    path: '/payment/reservation/cancel/:resaId',
    name: 'profile',
    component: () => import('../views/payment/reservation/all.vue')
  },

  /* PAYMENT COMMANDE */
  {
    path: '/payment/commande/all/:commandeId',
    name: 'profile',
    component: () => import('../views/payment/commande/all.vue')
  },
  {
    path: '/payment/commande/accompte/:commandeId',
    name: 'profile',
    component: () => import('../views/payment/commande/accompte.vue')
  },
  {
    path: '/payment/commande/thanks/:commandeId',
    name: 'profile',
    component: () => import('../views/payment/commande/thanks.vue')
  },


  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/profile.vue'),
    meta: { requiresAuth: true }
  },

  /**************************** ADMIN ****************************/

  {
    path: '/admin',
    name: 'admin',
    component: () => import('../views/admin/dashboard.vue'),
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/admin/stats',
    name: 'admin-stats',
    component: () => import('../views/admin/stats.vue'),
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/admin/data/:entityType?',
    name: 'admin-table',
    component: () => import('../views/admin/table.vue'),
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/admin/create-entity/:entityType/:entityId?',
    name: 'admin-virtual-form',
    component: () => import('../views/admin/virtual-form.vue'),
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/admin/reservations',
    name: 'resas',
    component: () => import('../views/admin/reservations.vue'),
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/admin/commandes',
    name: 'resas',
    component: () => import('../views/admin/commandes.vue'),
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/admin/trafic',
    name: 'trafic',
    component: () => import('../views/admin/trafic.vue'),
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  /**************************** CUSTOM ****************************/

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start()
  }
  next()
})

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done()
})

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done()
  setTimeout(()=>{
    window.scrollTo(0, 0)
  }, 200)
  
})

//avant chaque changement de route (url dans le navitateur)
router.beforeEach(async (to, from, next) => {
  console.log("to", to.name, to.meta)
  if(to.meta != null //si cette route nécessite une authentification 
  && to.meta.requiresAuth == true){
    //vérifie que le user est connecté
    await store.dispatch('auth/checkAuth', null, { root: true })

    if(store.state.auth.isLogin) {//si le user est connecté
      if(to.meta != null //si cette route nécessite d'être admin
        && to.meta.requiresAdmin == true){
        if(store.getters['auth/isAdmin']) return next()//soit il est admin, et il peut y aller
        else return next('/login') //soit il est pas admin, il doit se logguer
      }else return next()
    }//si il n'est pas connecté, il doit se logguer
    else return next('/login')
  }else{//si la route n'a pas besoin d'authentification, il peut y aller
    return next()
  }
})


export default router
